import $ from 'jquery';
import _ from 'underscore';


var
	DEFAULTS = {
		// Hostname is required for google analytics tracking 'create' call
		hostname: 'auto',
		// Optional prefix for track pageview calls in tracked URLs
		pageviewPrefix: '',
		// Source of the google analytics script:
		source: '//www.google-analytics.com/analytics.js',
		// A list of additional initial calls before the first track pageview
		// will be send:
		initialCalls: [],
		// the object or string for the initial pageview
		pageviewParam: undefined,
		// enable automatic pageview after initializing
		autoPageview: true
	},
	NAMESPACE_SETTINGS = 'tracking-analytics:settings'
;


class Command {

	execute() {
		var cookie;

		// Test for given Google Analytics ID
		if (typeof this.settings.id !== 'string') {
			throw new Error('Missing Google Analytics ID');
		}

		// Inject Google Analytics Code
		(function(i, s, o, g, r, a, m) {
			i.GoogleAnalyticsObject = r;
			i[r] = i[r] || function() {
				(i[r].q = i[r].q || []).push(arguments);
			};
			i[r].l = 1 * new Date();
			a = s.createElement(o);
			m = s.getElementsByTagName(o)[0];
			a.async = 1;

			a.setAttribute('data-src', g);
			a.setAttribute('data-consent-interpretation-flag', 'googleAnalyticsAllowed');

			m.parentNode.insertBefore(a, m);
		})(window, document, 'script', this.settings.source, 'ga');

		window.ga('create', this.settings.id, this.settings.hostname);
		window.ga('set', 'anonymizeIp', true);
		window.ga('require', 'displayfeatures');

		_.each(this.settings.initialCalls, args => {
			if (_.isArray(args)) {
				window.ga.apply(window.ga, args);
			}
		});

		if (this.settings.autoPageview) {
			window.ga('send', 'pageview', this.pageview);
		}

		this._initGTM();

		// Add opt out feature:
		cookie = 'ga-disable-' + this.settings.id;

		function optout() {
			document.cookie = cookie + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
			window[cookie] = true;
		}

		if (document.cookie.indexOf(cookie + '=true') > -1) {
			optout();
		}

		window.gaOptout = optout;
	}

	// Inject GTM Code
	_initGTM() {
		if (!this.settings.gtm) {
			return;
		}

		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
			const
				f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l !== 'dataLayer' ? `&l=${l}` : ''
			;
			j.async = true;
			j.src = `https://server.sgtm-legacy.kleinanzeigen.de/gtm.js?id=${i}${dl}`;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', this.settings.gtm);

		const event = {
			event: 'page_meta',
			page_type: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
		};

		// If the user has not given consent to Google Analytics, we push the event to a temporary dataLayer
		if (!window?.ekGdprConsentBanner?.internal?.isGoogleAnalyticsConsentGiven()) {
			window._dataLayer = window._dataLayer || [];
			window._dataLayer.push(event);

			// If consent is given, push all events from the temporary dataLayer to the real dataLayer
			document.addEventListener('consentInterpretationChanged', function(e) {
				if (e?.detail?.consentInterpretation?.googleAnalyticsAllowed && window?._dataLayer?.length) {
					window._dataLayer.forEach((event) => window.dataLayer.push(event));
					delete window._dataLayer;
				}
			});

			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(event);
	}

	get settings() {
		var settings = $.extend({}, DEFAULTS);

		// Load possible settings from registered plugins:
		if (this.context.hasWiring(NAMESPACE_SETTINGS)) {
			settings = $.extend(settings, this.context.getObject(NAMESPACE_SETTINGS));
		}

		return settings;
	}

	get pageview() {
		return this.settings.pageviewParam || (this.settings.pageviewPrefix + document.location.pathname);
	}

}

export default Command;
