import $ from 'jquery';
import {TrackPageview, TrackSocial} from 'picnic/TrackingAnalytics';
import AnalyticsInitialize from 'app/core/tracking-analytics/commands/Initialize';
import TrackEvent from 'app/core/tracking-analytics/commands/TrackEvent';
import RegistryService from 'app/core/tracking-registry/services/Registry.js';
import OutboundService from 'picnic/TrackingOutbound';


var
	NAMESPACE = 'tracking:',
	NAMESPACE_REGISTRY = NAMESPACE + 'registryservice',
	NAMESPACE_OUTBOUND = NAMESPACE + 'outboundservice',
	EVENT_TRACKPAGEVIEW = NAMESPACE + 'trackpageview',
	EVENT_TRACKEVENT = NAMESPACE + 'trackevent',
	EVENT_TRACKSOCIAL = NAMESPACE + 'tracksocial',
	SELECTOR_OUTBOUND = 'a',
	SELECTOR_CUSTOMOUTBOUND = '.custom-tracking',
	OUTBOUND_EVENT_CATEGORY = 'outbound',
	OUTBOUND_EVENT_ACTION = 'link'
;


class Command extends AnalyticsInitialize {
	execute() {
		this._registry = new RegistryService({context: this.context});

		// Wire registry...
		this.context.wireValue(NAMESPACE_REGISTRY, this._registry);

		// Initialize google analytics...
		super.execute();

		// Wire commands for analytics tracking:
		this.context.wireCommand(EVENT_TRACKPAGEVIEW, TrackPageview);
		this.context.wireCommand(EVENT_TRACKEVENT, TrackEvent);
		this.context.wireCommand(EVENT_TRACKSOCIAL, TrackSocial);

		// Create outbound service:
		// The outbound service watches all links on a page and when they
		// are clicked, it checks whether it is an internal link or an
		// external link (outbound). In the case of an external link, it
		// fires an event which should be tracked...
		this.context.wireValue(NAMESPACE_OUTBOUND, new OutboundService({
			context: this.context,
			selector: SELECTOR_OUTBOUND,
			eventName: EVENT_TRACKEVENT,
			eventData: {
				category: OUTBOUND_EVENT_CATEGORY,
				action: OUTBOUND_EVENT_ACTION
			}
		}));

		/* PII cleanup */
		var emailRegEx = /([\w-+.]+@[\w-.]+)/;
		window.ga('set', 'location', window.location.href.replace(emailRegEx, '%removed%'));
		if (emailRegEx.test(document.referrer)) {
			window.ga('set', 'referrer', document.referrer.replace(emailRegEx, '%removed%'));
		}

		$('body').on('click.custom-tracking', SELECTOR_CUSTOMOUTBOUND, (event) => {
			var
				category = event.currentTarget.getAttribute('data-category') || window.kleinanzeigen.trackingIdentifier,
				action = event.currentTarget.getAttribute('data-action'),
				label = event.currentTarget.getAttribute('data-label')
			;

			window.ga('send', 'event', category, action, label);

			window.dataLayer.push({
				event: 'gaEvent',
				eventAction: action,
				eventCategory: category,
				eventLabel: label,
				fieldsObject: {
					'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
				}
			});
		});

		$('body').on('focusout.input-tracking', 'input', (event) => {
			var element = event.currentTarget,
				category,
				action,
				baseLabel,
				inputLength,
				label;
			if (!$(element).hasClass('input-tracking')) {
				element = $(event.currentTarget).parents('.input-tracking')[0];
			}
			if (element) {
				category = element.getAttribute('data-category');
				action = element.getAttribute('data-action');
				baseLabel = element.getAttribute('data-label');
				inputLength = event.currentTarget.value.length;
				label = baseLabel + inputLength;
				window.ga('send', 'event', category, action, label, {
					'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
				});
				window.dataLayer.push({
					event: 'gaEvent',
					eventAction: action,
					eventCategory: category,
					eventLabel: label,
					fieldsObject: {
						'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
					}
				});
			}
		});

		this._registerAccordion();
		this._registerWizard();
		this._registerContact();
		this._registerSlideshow();
		this._initilizeScrollTracking();
	}

	_registerAccordion() {
		this._registry.registerEvent('cms:accordion:toggle', {
			category: '.category',
			action: '.action',
			label: '.label',
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});
	}

	_registerWizard() {
		this._registry.registerEvent('realestate:wizard:submit', {
			category: ({dataCategory}) => dataCategory,
			action: ({dataAction}) => dataAction,
			label: ({dataLabel}) => dataLabel,
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});
	}

	_registerContact() {
		this._registry.registerEvent('contact:success', {
			category: function(data) {
				if (data.dataCategory) {
					return data.dataCategory;
				}
				if (data.category === 'pro' || data.category === 'real-estate') {
					return `LandingPage${((data.category === 'pro') ? 'Pro' : 'RealEstate')}Lead`;
				}
				return window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK';
			},
			action: 'Web2LeadSentAttempt',
			label: function(data) {
				if (data.dataLabel) {
					return data.dataLabel;
				}
				if (data.category === 'pro' || data.category === 'real-estate') {
					return (data.category === 'pro') ? 'Pro' : 'RE_B2C';
				}
			},
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});

		this._registry.registerEvent('contact:removesuccess', {
			category: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
			action: '.action',
			label: '.label',
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});

		this._registry.registerEvent('contact:exitintent', {
			category: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
			action: 'UserInactive',
			label: '0',
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});
	}

	_registerSlideshow() {
		this._registry.registerEvent('slideshow:change', {
			category: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
			action: 'CarouselClick',
			label: 'Advantage'
		});
	}

	_initilizeScrollTracking() {
		var scrollThresholds = [25, 50, 75, 100],
			category = window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK';

		function scrollPercentage() {
			var currentPosition = $(window).height() + $(window).scrollTop(),
				documentHeight = $(document).height();
			return Math.floor(currentPosition / documentHeight * 100);
		}

		$(document).ready(function() {
			var currentPosition = scrollPercentage(),
				percentageValue = 0;
			// send initial position
			while (currentPosition >= Math.min(...scrollThresholds)) {
				percentageValue = scrollThresholds.shift();
			}
			window.ga('send', 'event', category, 'AboveField', percentageValue.toString(), {
				nonInteraction: true,
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			});
		});

		$(document).on('scroll', () => {
			var currentPosition = scrollPercentage();
			while (currentPosition >= Math.min(...scrollThresholds)) {
				// pop first threshold element and send
				window.ga('send', 'event', category, 'Scrolling', scrollThresholds.shift().toString(), {
					nonInteraction: true,
					'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
				});
			}
		});
	}
}

export default Command;
